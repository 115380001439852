import { render, staticRenderFns } from "./AddTips.vue?vue&type=template&id=2781cfe3&scoped=true&"
import script from "./AddTips.vue?vue&type=script&lang=js&"
export * from "./AddTips.vue?vue&type=script&lang=js&"
import style0 from "./AddTips.vue?vue&type=style&index=0&id=2781cfe3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2781cfe3",
  null
  
)

export default component.exports