<template>
    <b-card :title="$t('general.tips')" class="tipsClass">
        <div v-if="dataLoaded">
            <b-form-group :label="$t('general.business_unit')">
                <v-select v-model="businessUnitId" :options="this.businessUnits" :reduce="ele => ele.id" label="name" :clearable="false" class="mb-2" @input="loadTipsConfiguration"></v-select>
            </b-form-group>
            <b-form-checkbox switch v-model="tips.enabled" @change="changeInputTypeEnabled" :disabled="!$hasPermission($permissions.ConfigurationWrite)">
                {{ $t('config.tips.tips_enabled') }}
            </b-form-checkbox>
            <b-form-checkbox switch v-model="tips.manual_input" :disabled="!tips.enabled || !$hasPermission($permissions.ConfigurationWrite)">
                {{ $t('config.tips.manual_input_of_tips') }}
            </b-form-checkbox>
            <b-form-checkbox switch v-model="tips.automatic_tips" :disabled="!tips.enabled || !$hasPermission($permissions.ConfigurationWrite)">
                {{ $t('config.tips.automatic_tips') }}
            </b-form-checkbox>

            <validation-observer ref="automaticTips">
                <div v-if="tips.automatic_tips && tips.enabled" class="mb-1" >
                    <validation-provider #default="{ errors }" :name="$t('config.tips.automatic_tips')" rules="required">
                        <v-select :disabled="!$hasPermission($permissions.ConfigurationWrite)" v-model="tips.automatic_payment_type_id" :options="paymentTypes" label="name" :placeholder="$t('general.payment_types')" :reduce="ele => ele.id" />
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </div>

                <!--<b-form-checkbox switch v-model="tips.tips_like_product" :disabled="!tips.enabled || !$hasPermission($permissions.ConfigurationWrite)">
                    {{ $t('config.tips.tips_like_product') }}
                </b-form-checkbox>

                <validation-provider #default="{ errors }" :name="$t('config.tips.tips_like_product')" rules="required">
                    <div v-if="tips.tips_like_product && tips.enabled" class="mb-1" >
                        <v-select :disabled="!$hasPermission($permissions.ConfigurationWrite)" v-model="tips.vat_group_id" :options="vats" label="name" :placeholder="$t('table_fields.vat')" :reduce="ele => ele.id" />
                    </div>
                    <small class="text-danger">{{errors[0]}}</small>
                </validation-provider>-->
            </validation-observer>


            <b-form-checkbox switch v-model="tips.advance_input" :disabled="!tips.enabled || !$hasPermission($permissions.ConfigurationWrite)" @change="changeInputType">
                {{ $t('config.tips.advance_input') }}
            </b-form-checkbox>

            <div class="d-flex justify-content-end w-100 mb-2" v-if="tips.enabled && tips.advance_input && $hasPermission($permissions.ConfigurationWrite)">
                <b-button variant="primary" @click="addNewTipConfiguration">
                    {{ $t("general.add") }}
                </b-button>
            </div>

            <div class="position-relative mb-5" v-if="tips.enabled && tips.advance_input">
                <div style="width: 100%; height: 3px; background:transparent;" />
                <div :id="'tips-showcase' + id" v-for="(ele, id) of this.calculatedConfiguration" :key="'tips-showcase' + id" :class="[{'green': ele.type === 0}, {'red': ele.type === 1}]" style="position: absolute; height: 5px;"  :style="'left:' + ele.percentage + '%; width: ' + ele.percentage_width + '%;'">
                    <div class="dotBackground" style="position: absolute; width: 10px; height: 10px; top: -2.5px; border-radius: 50%;" />

                    <div style="position: absolute; top: 10px;" v-if="ele.type === 0">
                        {{ele.from}}€
                    </div>
                    <div style="position: absolute; top: -30px; color: #fa8b8b" v-else>
                        {{ele.from}}€
                    </div>
                    <b-tooltip :target="'tips-showcase' + id">
                        <div>
                            {{ $t("config.tips.start") }}: {{ele.from}}
                        </div>
                        <div>
                            {{ $t("config.tips.end") }}: {{ele.to}}
                        </div>
                    </b-tooltip>
                </div>
                <div v-if="calculatedConfiguration.length > 0">
                    <div class="dotBackground" style="position: absolute; width: 10px; height: 10px; top: 0; border-radius: 50%; right: 0; z-index: 10;" />
                    <div style="position: absolute; top: 5px; right: -5px; font-size: 25px">
                        ∞
                    </div>
                </div>

                <div id="tips-showcase_last_inifnity" v-if="calculatedConfiguration.length > 0" style="position: absolute; height: 5px;" :class="getClassForLastInterval()"  :style="'left:85%; width: 15%;'">
                    <div v-if="getClassForLastInterval() === 'red'">
                        <div class="dotBackground" style="position: absolute; width: 10px; height: 10px; top: -2.5px; border-radius: 50%;"/>
                        <div style="position: absolute; top: 10px;">
                            {{getLastEnteredInterval()}}€
                        </div>

                        <b-tooltip target="tips-showcase_last_inifnity">
                            <div>
                                {{ $t("config.tips.start") }}: {{getLastEnteredInterval()}}
                            </div>
                            <div>
                                {{ $t("config.tips.end") }}: ∞
                            </div>
                        </b-tooltip>
                    </div>
                    <div  v-if="this.tips.tips_array[this.tips.tips_array.length -1].from > 0">
                        <div class="dotBackground" style="position: absolute; width: 10px; height: 10px; top: -2.5px; border-radius: 50%;"/>

                        <b-tooltip target="tips-showcase_last_inifnity">
                            <div>
                                {{ $t("config.tips.start") }}: {{this.tips.tips_array[this.tips.tips_array.length -1].from}}
                            </div>
                            <div>
                                {{ $t("config.tips.end") }}: ∞
                            </div>
                        </b-tooltip>

                        <div style="position: absolute; top: 10px;">
                            {{this.tips.tips_array[this.tips.tips_array.length -1].from}}€
                        </div>
                    </div>
                </div>
            </div>

            <b-tabs active-nav-item-class="customTabActive" nav-class="navItemCustom" content-class="contentClass" v-if="tips.enabled && tips.advance_input">
                <b-tab v-for="(item, id) of tips.tips_array" :key="'tips_interval_'+id">
                    <template #title>
                        <span class="d-flex align-items-center" v-html="getIntervalName(item)" />
                        <span v-if="!validTabs[id]" class="redExclamationMark d-flex align-items-center" style="font-weight: bold; font-size: 18px; height: 20px; padding-left: 2px;">!</span>
                    </template>

                    <validation-observer :ref="'tipsValidationObserver' + id">
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <div style="font-weight: bold; font-size: 18px;" class="d-flex align-items-center">
                                {{$t('config.tips.interval')}}
                            </div>
                            <div>
                                <b-button size="sm" variant="danger" @click="deleteInterval(id)" v-if="item.from !== 0 && item.to !== -1 && $hasPermission($permissions.ConfigurationWrite)">
                                    {{$t('config.tips.delete_interval')}}
                                </b-button>
                            </div>
                        </div>

                        <div style="height: 40px;" v-show="!validTabs[id]">
                            <b-alert  variant="danger" :show="true" class="d-flex align-items-center" style="border-radius: 18px;">
                                <span class="redExclamationMark d-flex align-items-center" style="font-weight: bold; padding: 5px 15px;">
                                    {{$t("config.tips.interval_configuration_not_valid")}}
                                </span>
                            </b-alert>
                        </div>

                        <div class="row mb-1">
                            <div class="col-6 d-flex align-items-center">
                                {{$t('config.tips.interval_start')}}
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                {{$t('config.tips.interval_end')}}
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-6">
                                <validation-provider #default="{ errors }" v-if="id > 0" :name="$t('config.tips.interval_start')" :rules="getIntervalValidation(id)">
                                    <b-form-input v-model.number="item.from" @input="debounce" :disabled="!$hasPermission($permissions.ConfigurationWrite)"/>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                                <b-form-input v-else value="0" :disabled="true"/>
                            </div>
                            <div class="col-6">
                                <validation-provider v-if="item.to !== -1" #default="{ errors }" :name="$t('config.tips.interval_end')" :rules="getIntervalValidation(id)">
                                    <b-form-input v-model.number="item.to" v-if="item.to !== -1" class="mb-2" @input="debounce" :disabled="!$hasPermission($permissions.ConfigurationWrite)"/>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                                <b-form-input v-else value="∞" :disabled="true"/>
                            </div>
                        </div>

                        <div>
                            <b-form-group :label="$t('config.tips.tip_unit')" >
                                <v-select :disabled="!$hasPermission($permissions.ConfigurationWrite)" v-model="item.input_type" :options="tips_options" :reduce="ele => ele.value"/>
                            </b-form-group>
                            <b-form-checkbox v-model="item.display_both_types"  switch class="mt-2" :disabled="!$hasPermission($permissions.ConfigurationWrite)">{{$t('config.tips.display_both_types')}}</b-form-checkbox>
                        </div>

                        <div class="mt-2">
                            <div style="font-weight: bold; font-size: 18px;" class="mb-2">
                                {{$t('config.tips.tips_options')}}
                            </div>
                            <div v-if="item.tips && item.tips.length > 0">
                                <div class="ml-2 mb-1">
                                    <div class="d-flex align-items-center">
                                        {{$t('config.tips.value')}}
                                    </div>
                                </div>
                                <div class="d-flex mb-2" v-for="(itemInner, idInner) of item.tips" :key="'tips_interval_'+ + idInner">
                                    <div v-if="$hasPermission($permissions.ConfigurationWrite)" :id="'explain_not_allowed_to_delete_' + idInner" style="width: 30px; height: 38px;" class="d-flex justify-content-start align-items-center enabled" @click="deleteItem(id,idInner)">
                                        <feather-icon
                                            icon="XIcon"
                                            size="21"
                                        />
                                    </div>

                                    <div class="w-100">
                                        <validation-provider #default="{ errors }" :name="idInner + '. '+$t('config.tips.value')" rules="required|is_number|min_value:0|is_number|min_value:0|decimalCustom:2|mustBePositive:true">
                                            <b-input-group :append="item.input_type === TipsType.Money? '€': '%' ">
                                                <b-form-input v-model="item.tips[idInner]" placeholder="0" @input="debounce" :disabled="!$hasPermission($permissions.ConfigurationWrite)"/>
                                            </b-input-group>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div v-show="!item.tips || item.tips.length === 0">
                                <b-alert variant="info" :show="true" class="infoAlert d-flex align-items-center" style="border-radius: 18px;">
                                    <span style="font-weight: bold; padding: 5px 15px;">
                                        {{$t("config.tips.no_tips_added_info")}}
                                    </span>
                                </b-alert>
                            </div>

                            <div class="d-flex justify-content-end my-2" v-if="$hasPermission($permissions.ConfigurationWrite)">
                                <b-button :disabled="item.tips.length >= 6" variant="primary" @click="addItem(id)">{{$t('general.add')}}</b-button>
                            </div>
                        </div>
                    </validation-observer>
                </b-tab>
            </b-tabs>

            <div v-if="tips.enabled  && !tips.advance_input" class="mt-2">
                <div class="customCard">

                    <div v-if="tips && tips.tips_array && tips.tips_array.length > 0">
                        <div v-for="(item, id) of tips.tips_array" :key="'tips_manual_inpur'+id">
                            <div>
                                <b-form-group :label="$t('config.tips.tip_unit')" >
                                    <v-select v-model="item.input_type" :disabled="!$hasPermission($permissions.ConfigurationWrite)" :options="tips_options" :reduce="ele => ele.value"/>
                                </b-form-group>
                                <b-form-checkbox v-model="item.display_both_types" :disabled="!$hasPermission($permissions.ConfigurationWrite)" switch class="mt-2">{{$t('config.tips.display_both_types')}}</b-form-checkbox>
                            </div>
                            <div v-if="item.tips.length>0" class="mt-2">
                                <div style="font-weight: bold; font-size: 18px;" class="mb-2 ">
                                    {{$t('config.tips.tips_options')}}
                                </div>
                                <div class="ml-2 mb-1 mt-2">
                                    <div class="d-flex align-items-center">
                                        {{$t('config.tips.value')}}
                                    </div>
                                </div>
                                <validation-observer :ref="'tipsValidationObserver' + id">
                                    <div class="d-flex mb-2" v-for="(itemInner, idInner) of item.tips" :key="'tips_interval_'+ + idInner">
                                        <div v-if="$hasPermission($permissions.ConfigurationWrite)" :id="'explain_not_allowed_to_delete_' + idInner" style="width: 30px; height: 38px;" class="d-flex justify-content-start align-items-center enabled" @click="deleteItem(id,idInner)" >
                                            <feather-icon
                                                icon="XIcon"
                                                size="21"
                                            />
                                        </div>
                                        <div class="w-100">
                                            <validation-provider #default="{ errors }" :name="idInner + '. '+$t('config.tips.value')" rules="required|is_number|min_value:0|is_number|min_value:0|decimalCustom:2|mustBePositive:true">
                                                <b-input-group :append="item.input_type === TipsType.Money? '€': '%' ">
                                                    <b-form-input v-model="item.tips[idInner]" placeholder="0" @input="debounce" :disabled="!$hasPermission($permissions.ConfigurationWrite)"/>
                                                </b-input-group>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </validation-observer>

                            </div>
                            <div v-show="!item.tips || item.tips.length === 0">
                                <b-alert variant="info" :show="true" class="d-flex align-items-center" style="border-radius: 18px;">
                                    <span style="font-weight: bold; padding: 5px 15px;">
                                        {{$t("config.tips.no_tips_added_info")}}
                                    </span>
                                </b-alert>
                            </div>

                            <div class="d-flex justify-content-end mt-2" v-if="$hasPermission($permissions.ConfigurationWrite)">
                                <b-button variant="primary" :disabled="item.tips.length >= 6" @click="addItem(id)">{{$t('general.add')}}</b-button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="d-flex justify-content-end mt-2" v-if="$hasPermission($permissions.ConfigurationWrite)">
                <b-button variant="primary" :disabled="!isConfigurationValid" @click="saveConfiguration">
                    {{ $t("general.save") }}
                </b-button>
            </div>
        </div>
        <add-tips ref="component_add_tips" :array="tips.tips_array" @addedInterval="addNewInterval" />
    </b-card>
</template>

<script>
    import {BCard, BButton, BTooltip, BFormCheckbox, BTab, BTabs, BFormInput, BAlert, BFormGroup, BInputGroup} from 'bootstrap-vue'
    import AddTips from '@/views/Tips/AddTips.vue'
    import {TipsType} from '@/libs/enums/Tips'
    import vSelect from 'vue-select'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import * as Sentry from '@sentry/vue'
    import Vue from 'vue'


    export default {
        components:{
            BInputGroup,
            BFormGroup,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            BFormInput,
            AddTips,
            BCard,
            BButton,
            BTooltip,
            BFormCheckbox,
            BTab,
            BTabs,
            BAlert
        },
        computed: {
            TipsType() {
                return TipsType
            },
            calculatedConfiguration() {
                let array = []

                if (!this.tips.tips_array || this.tips.tips_array.length === 0) {
                    return array
                }

                let tmpConfiguration = JSON.parse(JSON.stringify(this.tips.tips_array))

                tmpConfiguration = this.removeInvalidItems(tmpConfiguration)

                for (const index in tmpConfiguration) {
                    if (tmpConfiguration[index].to === -1) {
                        tmpConfiguration[index].to =  tmpConfiguration[index].from
                    }
                }

                tmpConfiguration = tmpConfiguration.filter((ele) => ele.to > 0)

                const sortedTips = JSON.parse(JSON.stringify(tmpConfiguration))

                sortedTips.sort((a, b) => {
                    const aParsed = parseFloat(a.from)
                    const bParsed = parseFloat(b.from)

                    if (aParsed === bParsed) {
                        return 0
                    } else if (aParsed < bParsed) {
                        return -1
                    }

                    return 1
                })


                for (const index in tmpConfiguration) {
                    const ele = tmpConfiguration[index]
                    const id = parseInt(index)

                    if (ele.to > 0) {
                        array.push({
                            value: ele.value,
                            from: ele.from,
                            to: ele.to,
                            type: 0
                        })
                    }


                    if (id + 1 < sortedTips.length) {
                        if (ele.to !== sortedTips[id + 1].from && ele.to > 0) {
                            array.push({
                                value: '',
                                from: ele.to,
                                to: sortedTips[id + 1].from,
                                type: 1
                            })
                        }
                    }

                    const first = array[0]
                    const last = array[array.length - 1]

                    const diffrence = last.to - first.from

                    let calculatedPercentage = 0

                    for (const index in array) {
                        const ele = array[index]

                        let percentageOfAll = 10

                        if (ele.to > 0) {
                            percentageOfAll = ((ele.to - ele.from) / diffrence) * 85
                        }

                        array[index].percentage = calculatedPercentage
                        array[index].percentage_width = percentageOfAll
                        calculatedPercentage += percentageOfAll
                    }
                }

                array = this.mergeIntervals(array)

                return array
            },
            isConfigurationValid() {
                return this.validTabs.every((ele) => ele) && this.calculatedConfiguration.findIndex((ele) => ele.type === 1) === -1
            }
        },
        data() {
            return {
                skipRecalucationOfTimeline: false,
                loadingConfiguration: false,
                dataLoaded: false,
                businessUnitId: '',
                businessUnits: [],
                vats: [],
                paymentTypes: [],
                tips: {
                    enabled: false,
                    manual_input: false,
                    advance_input: false,
                    automatic_tips: false,
                    tips_like_product: false,
                    automatic_payment_type_id: '',
                    vat_group_id: '',
                    tips_array: [
                        {
                            from: 0,
                            to: '',
                            input_type: TipsType.Money,
                            display_both_types: false,
                            tips: []
                        },
                        {
                            from: '',
                            to: -1,
                            input_type: TipsType.Money,
                            display_both_types: false,
                            tips: []
                        }
                    ]
                },
                validTabs: [],
                tips_options: [
                    {
                        value: TipsType.Money,
                        label: this.$t('config.tips.money')
                    },
                    {
                        value: TipsType.Percentage,
                        label: this.$t('config.tips.percentage')
                    }
                ],
                debounceTimer: null
            }
        },
        methods:{
            removeInvalidItems(array) {
                const tmpArray = []
                for (const index in array) {
                    const ele = array[index]
                    const id = parseInt(index)
                    if ((ele.from === 0 && id === 0) || (ele.to === -1 && id === array.length - 1)) {
                        tmpArray.push(ele)
                        continue
                    }
                    if (ele.from > ele.to) {
                        continue
                    }

                    if (id - 1 >= 0 && array[id - 1].to <= array[id].from && (id + 1 >= array.length || array[id + 1].from >= array[id].to)) {
                        tmpArray.push(ele)
                    }


                }
                return tmpArray
            },
            deleteInterval(id) {
                this.tips.tips_array.splice(id, 1)
            },
            debounce() {
                if (this.debounceTimer) {
                    clearTimeout(this.debounceTimer)
                }

                this.debounceTimer = setTimeout(() => {
                    this.validateFields()
                }, 500)
            },
            async validateFields() {
                await this.$nextTick()
                for (const id in this.tips.tips_array) {
                    if (this.$refs && this.$refs[`tipsValidationObserver${  id}`] && this.$refs[`tipsValidationObserver${  id}`].length > 0) {
                        const valid = await this.$refs[`tipsValidationObserver${  id}`][0].validate()
                        Vue.set(this.validTabs, id, valid)
                    }
                }
            },
            async saveConfiguration() {
                if (!await this.$refs.automaticTips.validate()) {
                    return
                }

                try {
                    const tmpArray = JSON.parse(JSON.stringify(this.tips))
                    for (const id in tmpArray.tips_array) {
                        tmpArray.tips_array[id].from = Number(Number(Number(tmpArray.tips_array[id].from) * 1000000).toFixed(0))
                        if (tmpArray.tips_array[id].to > 0) {
                            tmpArray.tips_array[id].to =  Number(Number(Number(tmpArray.tips_array[id].to) * 1000000).toFixed(0))
                        }

                        for (const idInner in tmpArray.tips_array[id].tips) {
                            tmpArray.tips_array[id].tips[idInner] = Number(Number(Number(tmpArray.tips_array[id].tips[idInner]) * 1000000).toFixed(0))
                        }
                    }

                    await this.$http.post(`/api/client/v1/tips/${  this.businessUnitId}`, tmpArray)
                    this.$printSuccess(this.$t('print.success.config_updated'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            },
            async loadTipsConfiguration(initial = false) {
                try {
                    this.loadingConfiguration = true
                    const res = await this.$http.get(`/api/client/v1/tips/${  this.businessUnitId}`)
                    const tips = res.data

                    if (initial) {
                        this.tips.enabled = tips.enabled
                        this.tips.manual_input = tips.manual_input
                        this.tips.automatic_tips = tips.automatic_tips
                        this.tips.tips_like_product = tips.tips_like_product
                        this.tips.advance_input = tips.advance_input
                        this.tips.automatic_payment_type_id = tips.automatic_payment_type_id
                        this.tips.vat_group_id = tips.vat_group_id
                    }

                    this.validTabs = []
                    if ((tips.tips_array && tips.tips_array.length === 0) || (this.tips.advance_input !== tips.advance_input)) {
                        if (!this.tips.enabled) {
                            this.tips.advance_input = false
                        }

                        if (this.tips.advance_input) {
                            this.tips.tips_array = [
                                {
                                    from: 0,
                                    to: '',
                                    input_type: TipsType.Money,
                                    display_both_types: false,
                                    tips: []
                                },
                                {
                                    from: '',
                                    to: -1,
                                    input_type: TipsType.Money,
                                    display_both_types: false,
                                    tips: []
                                }
                            ]
                        } else {
                            this.tips.tips_array = [
                                {
                                    from: 0,
                                    to: -1,
                                    input_type: TipsType.Money,
                                    display_both_types: false,
                                    tips: []
                                }
                            ]
                        }
                    } else {

                        for (const id in tips.tips_array) {
                            tips.tips_array[id].from = Number(this.$numberRounding(tips.tips_array[id].from).replace('.00', '').replace(',00', ''))
                            if (tips.tips_array[id].to > 0) {
                                tips.tips_array[id].to =  Number(this.$numberRounding(tips.tips_array[id].to).replace('.00', '').replace(',00', ''))
                            }

                            for (const idInner in tips.tips_array[id].tips) {
                                tips.tips_array[id].tips[idInner] = Number(this.$numberRounding(tips.tips_array[id].tips[idInner]).replace('.00', '').replace(',00', ''))
                            }
                        }

                        this.tips.tips_array = tips.tips_array

                        for (let i = 0; i < this.tips.tips_array.length; i++) {
                            this.validTabs.push(true)
                        }
                    }

                    await this.validateFields()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.loadingConfiguration = false
                }
            },
            async loadData() {
                try {
                    const res = this.$http.get('/api/client/v1/business_units/')
                    const res1 = this.$http.get('/api/client/v1/payment_types/')
                    const res2 = this.$http.get('/api/client/v1/vat_groups/')

                    const responses = await Promise.all([res, res1, res2])
                    this.businessUnits = responses[0].data ?? []
                    this.paymentTypes = responses[1].data ?? []
                    this.vats = responses[2].data ?? []

                    if (this.businessUnits && this.businessUnits.length > 0) {
                        this.businessUnitId = responses[0].data[0].id
                    }

                    await this.loadTipsConfiguration(true)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.dataLoaded = true
                }
            },
            async changeInputTypeEnabled() {
                await this.$nextTick()
                if (this.tips.enabled) {
                    await this.loadTipsConfiguration(false)
                }
            },
            async changeInputType() {
                await this.$nextTick()
                await this.loadTipsConfiguration(false)
            },
            mergeIntervals(array) {
                const minIntervalWidth = 5
                const newArray = []

                if (array.length === 0) {
                    return newArray
                }

                let tmpItem = JSON.parse(JSON.stringify(array[0]))

                for (const index in array) {
                    const id = parseInt(index)
                    const item = JSON.parse(JSON.stringify(array[id]))
                    if (id === 0 || tmpItem === null) {
                        tmpItem = JSON.parse(JSON.stringify(item))
                        continue
                    }

                    if (item.type === 1 || item.percentage_width > minIntervalWidth) {
                        if (tmpItem !== null) {
                            newArray.push(tmpItem)
                        }

                        newArray.push(item)
                        tmpItem = null
                    } else {
                        tmpItem.to = item.to
                        tmpItem.percentage_width += item.percentage_width
                    }
                }

                if (tmpItem !== null) {
                    newArray.push(tmpItem)
                }

                return newArray
            },
            getClassForLastInterval() {
                const item = this.tips.tips_array.find((ele) => ele.to === -1)
                if (item && item.from) return 'green'

                return 'red'
            },
            getLastEnteredInterval() {
                let maxItem = 0
                for (const item of this.tips.tips_array) {
                    if (maxItem < item.to) {
                        maxItem = item.to
                    }
                }

                return maxItem
            },
            getIntervalValidation(id) {
                let classes = 'required|is_number|decimalCustom:2|mustBePositive:true'
                if (id > 0 && this.tips.tips_array[id - 1].to) {
                    classes += `|min_value:${  this.tips.tips_array[id - 1].to}`
                }

                if (id + 1 < this.tips.tips_array.length && this.tips.tips_array[id + 1].from) {
                    classes += `|max_value:${  this.tips.tips_array[id + 1].from}`
                }

                return classes
            },
            sortIntervals(a, b) {
                const aFrom = parseFloat(a.from)
                const bFrom = parseFloat(b.from)
                const aTo = parseFloat(a.to)
                const bTo = parseFloat(b.to)

                if (aFrom ===  bFrom) return 0

                if (bTo === -1)   {
                    return -1
                }

                if (aTo === -1)   {
                    return 1
                }


                if (aFrom < bFrom) return -1
                if (aFrom > bFrom) return 1
                return 0
            },
            getIntervalName(item) {
                let from = 'x€'
                let to = 'x€'

                if (item.from !== '') {
                    from = `${item.from  }€`
                }


                if (item.to === -1) {
                    to = '<span style="font-size: 24px; padding-left: 3px; height: 20px;" class="d-flex align-items-center">∞</span>'
                } else  if (item.to !== '') {
                    to = `${item.to  }€`
                }

                return `${from  } - ${  to}`
            },
            deleteItem(id, index) {
                this.tips.tips_array[id].tips.splice(index, 1)
                this.validateFields()
            },
            addNewTipConfiguration() {
                this.$refs.component_add_tips.openModal()
            },
            addNewInterval(item) {
                const index = this.tips.tips_array.findIndex((ele) => ele.from === 0)
                if (index >= 0) {
                    if (this.tips.tips_array[index].to > item.from || !this.tips.tips_array[index].to) {
                        this.tips.tips_array[index].to = item.from
                    }
                }

                const lastIndex = this.tips.tips_array.findIndex((ele) => ele.to === -1)
                if (lastIndex >= 0) {
                    if (item.to > this.tips.tips_array[lastIndex].from || !this.tips.tips_array[lastIndex].from) {
                        this.tips.tips_array[lastIndex].from = item.to
                    }
                }


                this.tips.tips_array.push(item)
                this.tips.tips_array.sort(this.sortIntervals)
                this.validateFields()
            },
            async addItem(id) {
                if (this.tips.tips_array[id].tips.length >= 6) {
                    return
                }

                this.tips.tips_array[id].tips.push('')

                this.validateFields()
            }
        },
        async mounted() {
            this.validTabs = []

            await this.loadData()
        }
    }
</script>

<style lang="scss">
    .dark-layout {
      .tipsClass{
        .customCard, .customTabActive, .contentClass {
          background: #343434 !important;
          color: white;
        }

        .dotBackground{
          background: white;
        }
      }
    }

    .light-layout {
      .tipsClass{
        .customCard, .customTabActive, .contentClass {
          background: rgba(247, 247, 247, 0.97) !important;
          color: black;
        }

        .dotBackground{
          background: #343434;
        }

        .v-select{
          background: white;
          border-radius: 5px;
        }
      }
    }


  .tipsClass{
    .customCard{
      border-radius: 18px;
      padding: 25px 20px;
    }

    .enabled {
      cursor: pointer;
      color: #f37979;
    }
    .green {
      background: #a0f5a0;
    }

    .redExclamationMark {
      color: #fa8b8b !important;
    }

    .red {
      background: #fa8b8b !important;
    }

    .customTabActive{
      border-top-left-radius: 18px !important;
      border-top-right-radius: 18px !important;
      border: none !important;
    }

    .contentClass{
      padding: 10px 15px;
      border-bottom-left-radius: 18px !important;
      border-bottom-right-radius: 18px !important;
    }

    .navItemCustom{
      margin-bottom: 0 !important;
    }

    .customTabActive::after{
      display: none;
    }
  }

</style>