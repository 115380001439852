<template>
    <b-modal hide-footer ref="modal_add_tips" :title="$t('config.tips.add_interval')">
        <validation-observer ref="addingTipsValidation">

            <b-alert variant="danger" :show="true" class="px-2 py-1 d-flex align-items-center" v-if="getIntervalInterception">Interval se prekirva z intervalom
                ({{getIntervalInterception.from}}€ -
                <span v-if="getIntervalInterception.to !== -1">
                    {{getIntervalInterception.to}}€
                </span>
                <span v-else class="d-flex align-items-center" style="font-size: 26px; height: 20px; padding-top: 3px; padding-left: 5px;">
                    ∞
                </span>
                )
            </b-alert>

            <validation-provider #default="{ errors }" :name="$t('config.tips.interval_start')" rules="required|is_number|decimalCustom:2|mustBePositive:true|min_value:0">
                <b-form-group :label="$t('config.tips.interval_start')">
                    <b-form-input v-model="interval.from" placeholder="0" />
                </b-form-group>
                <small class="text-danger">{{errors[0]}}</small>
            </validation-provider>

            <b-form-group :label="$t('config.tips.interval_end')" class="mt-2">
                <validation-provider #default="{ errors }" :name="$t('config.tips.interval_end')" rules="required|is_number|decimalCustom:2|mustBePositive:true|min_value:0">
                    <b-form-input v-model="interval.to" placeholder="0"/>
                    <small class="text-danger">{{errors[0]}}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group label="Enota za vnos napitnin"  class="mt-2">
                <v-select v-model="interval.input_type" :options="tips_options" :reduce="ele => ele.value"/>
            </b-form-group>
            <b-form-checkbox v-model="interval.display_both_types"  switch class="mt-2">{{$t('config.tips.display_both_types')}}</b-form-checkbox>

            <div class="d-flex justify-content-end mb-1 mt-2">
                <b-button variant="primary" @click="confirm">
                    {{$t('general.accept')}}
                </b-button>
            </div>

        </validation-observer>
    </b-modal>
</template>

<script>
    import {BModal, BFormInput, BFormGroup, BButton, BAlert, BFormCheckbox} from 'bootstrap-vue'
    import {TipsType} from '@/libs/enums/Tips'
    import vSelect from 'vue-select'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BAlert,
            BModal,
            BFormInput,
            BFormGroup,
            BButton,
            vSelect,
            BFormCheckbox
        },
        computed: {
            getIntervalInterception() {
                let interval = null

                const itemIn = JSON.parse(JSON.stringify(this.interval))

                if (!this.array || this.array.length <= 0) return interval

                if (!this.interval.from || !this.interval.to) {
                    return interval
                }

                itemIn.from = parseFloat(itemIn.from)
                itemIn.to = parseFloat(itemIn.to)

                for (const index in this.array) {
                    const id = parseInt(index)
                    const item = JSON.parse(JSON.stringify(this.array[id]))

                    if ((item.from === 0 && !item.from) || (item.to === -1 || !item.to)) {
                        continue
                    }


                    item.from = parseFloat(item.from)
                    item.to = parseFloat(item.to)

                    const orgValue = item.to

                    if (item.to === -1) {
                        item.to = Number.MAX_SAFE_INTEGER
                    }

                    if (itemIn.from >= item.from && (itemIn.from < item.to && item.to !== Number.MAX_SAFE_INTEGER)) {
                        item.to = orgValue
                        interval = item
                        break
                    }


                    if (itemIn.from <= item.from && (itemIn.to > item.from || itemIn.to >= item.to)) {
                        item.to = orgValue
                        interval = item
                        break
                    }
                }

                return interval
            }
        },
        data() {
            return {
                interval: {
                    from: '',
                    to: '',
                    input_type: TipsType.Money,
                    display_both_types: false,
                    tips: []
                },
                tips_options: [
                    {
                        value: TipsType.Money,
                        label: this.$t('config.tips.money')
                    },
                    {
                        value: TipsType.Percentage,
                        label: this.$t('config.tips.percentage')
                    }
                ]
            }

        },
        props:{
            array: {
                type: Array
            }
        },
        methods:{
            openModal() {
                this.$refs.modal_add_tips.show()
            },
            closeModal() {
                this.$refs.modal_add_tips.hide()
            },
            async confirm() {
                const valid = await this.$refs.addingTipsValidation.validate()
                if (!valid) return

                this.interval.from = parseFloat(this.interval.from)
                this.interval.to = parseFloat(this.interval.to)

                this.$emit('addedInterval', JSON.parse(JSON.stringify(this.interval)))
                this.interval = {
                    from: 0,
                    to: 0,
                    input_type: TipsType.Money,
                    display_both_types: false,
                    tips: []
                }
                this.closeModal()
            }
        }
    }
</script>

<style scoped>
    .greyedOut {
        //cursor: not-allowed !important;
        color: #838383;
    }
    .enabled {
        //cursor: pointer;
        color: #f37979;
    }
</style>

<style>
</style>